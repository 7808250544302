


















































































































































import { Component, Emit, Model, Vue, Watch } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";
import { mask } from "vue-the-mask";

@Component({
  components: {
    phone_: () => import("@/app/modules/service/builder/elements/Phone.vue")
  },
  directives: {
    mask
  }
})
export default class RegisterForm extends Vue {
  @Model("input", { type: Object }) readonly userData!: object;

  @Emit()
  input() {
    return this.localUserData;
  }

  localUserData!: object;

  valid: boolean = true as boolean;
  errors: object = {} as object;

  rules = {
    required: Rules.required,
    birthdate: Rules.birthdate,
    email: Rules.email
  };

  constructor() {
    super();

    this.localUserData = this.userData;
  }

  get salutations() {
    const salutations = [
      { long_name: this.$t("PROFILE.SALUTATIONS.NULL"), id: null },
      ...this.$store.getters["profile/salutations"]
    ];

    return [...salutations.reduce((map, _) => map.set(_.long_name, _), new Map()).values()];
  }

  @Watch("localUserData", { deep: true })
  onLocalUserDataChanged() {
    this.input();
  }
}
